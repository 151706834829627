<template>
    <main>
        <b-row class="match-height">
            <b-col cols="12">
                <b-jumbotron id="jumDts">
                    <div class="container-fluid">
                        <div class="row">
                            <main class="col-sm-12 text-center pb-2">
                                <h1 class="text-success">{{$t(`message.dashboard`)}}</h1>
                                <p class="text-secondary">{{$t(`message.welcometosft`)}}</p>
                            </main>
                        </div>
                    </div>
                </b-jumbotron>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="8" lg="7" md="12">
                <chartjs-bar-chart />
            </b-col>
            <b-col xl="4" lg="5" md="12">
                <b-card no-body>
                    <b-tabs pills card id="tabsLatest">
                        <b-tab :title='$t(`message.pendingoffers`)' active>
                            <b-card-text>
                                <div v-if="finishOffer">
                                    <b-list-group class="mb-2" v-for="(ar, i) in appResults" :key="i">
                                        <b-list-group-item>Name: <b-badge variant="success">{{ ar.offerName }}</b-badge></b-list-group-item>
                                        <b-list-group-item>Offered Products: <b-badge class="spanInfo" variant="success">{{ ar.offered_products }}</b-badge></b-list-group-item>
                                        <b-list-group-item class="tripInfo">Trip Information
                                            <b-list-group class="tripInfoGr" v-if="ar.tripDts">
                                                <b-list-group-item>Start Date: <b-badge variant="success">{{ ar.tripDts.start_date || '-' }}</b-badge></b-list-group-item>
                                                <b-list-group-item>End Date: <b-badge variant="success">{{ ar.tripDts.end_date || '-' }}</b-badge></b-list-group-item>
                                                <b-list-group-item>Trip Duration: <b-badge variant="success">{{ ar.tripDts.days || '-' }}</b-badge></b-list-group-item>
                                                <b-list-group-item>Residence: <b-badge variant="success">{{ ar.tripDts.res_name || '-' }}</b-badge></b-list-group-item>
                                                <b-list-group-item>Destination: <b-badge variant="success">{{ ar.tripDts.des_name || '-' }}</b-badge></b-list-group-item>
                                                <b-list-group-item>Number of Travellers: <b-badge variant="success">{{ ar.tripDts.travellersNumber || '-' }}</b-badge></b-list-group-item>
                                            </b-list-group>
                                        </b-list-group-item>
                                        <b-list-group-item>Date: <b-badge variant="success">{{ ar.offerDate }}</b-badge></b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" v-else>
                                    <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title='$t(`message.recent_activity`)'>
                            <b-card-text>
                                <div v-if="finishActivity">
                                    <b-list-group class="mb-2" v-for="(act, i) in activityArr" :key="i">
                                            <b-list-group-item class="d-flex justify-content-between">
                                                <div>
                                                    <b>{{ act.date }}</b>
                                                </div>
                                                <div>
                                                    {{ act.policyType }}<b-badge variant="primary" pill>{{ act.full_amount }}€</b-badge>
                                                </div>
                                            </b-list-group-item>
                                        <b-list-group-item class="d-flex justify-content-between">
                                            <div>
                                                {{ act.customer_name }} ({{ act.email }})
                                            </div>
                                            <div>
                                                <b-button size="sm" variant="outline-success" :href="'/information?t='+act.tokenized">Info</b-button>
                                            </div>
                                            
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" v-else>
                                    <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </main>
</template>
<script>
    import ChartjsBarChart from '@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue';
    import { cookie, token_id, products } from '@/products';
    import { BRow, BCol, BJumbotron, BTable, BButton, BCard, BCardHeader, BCardBody, BCardText, BCardTitle, BSpinner, BListGroup, BListGroupItem, BBadge, BTab, BTabs } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BJumbotron,
            BTable,
            BButton,
            BCard,
            BCardHeader, 
            BCardBody,
            BCardText,
            BCardTitle,
            BSpinner,
            BListGroup,
            BListGroupItem,
            BBadge,
            BTab,
            BTabs,
            ChartjsBarChart
        },
        data() {
            return {
                finishOffer: false,
                finishActivity: false,
                token_id,
                products,
                appResults: [],
                activityArr: [],
                cookie
            }
        },
        async created() {
            await this.fetchOffers();
            await this.fetchActivity();
        },
        methods: {
            async fetchActivity() {
                const url= {
                    prod: 'https://api-quote.safetravel.today/v2/app/recent_activity'
                };
                const data = {
                    token : this.token_id,
                    jwt: this.cookie
                }
                try {
                    await fetch(url.prod, {
                        method: 'POST',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then((response)=>response.json())
                    .then((res)=> {
                        if (res === 'not_valid') {
                            window.location.href = '/logout';
                            return;
                        }
                        res.forEach(r => {
                            this.activityArr.push({
                                customer_name: r?.customer_name ?? '-',
                                email: r?.email ?? '-',
                                date: r?.date ?? '-',
                                full_amount: r?.full_amount ?? '-',
                                tokenized: r?.tokenized ?? '-',
                                policyType: this.products[r?.policyType] ?? '-',
                            })
                        });
                    })
                } catch(err) {
                    console.warn(err)
                }
                finally {
                    this.finishActivity = true;
                }
            },
            async fetchOffers() {
                const url= {
                    prod: 'https://api-quote.safetravel.today/v2/app/get_last_offers'
                };
                const data = {
                    token : this.token_id,
                    jwt: this.cookie
                }
                try {
                    await fetch(url.prod, {
                        method: 'POST',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then((response)=>response.json())
                    .then((resp)=> {
                        if (resp === 'not_valid') {
                            window.location.href = '/logout';
                            return;
                        }
                        resp.forEach((res, index) => {
                            const offer_products = [];
                            if (res?.offered_products) {
                                for (const op in JSON.parse(res?.offered_products)) {
                                    offer_products.push(this.products[op]);
                                }
                            }
                            if (res?.app) {
                                this.appResults.push({
                                    'offerName': res?.name ?? '-',
                                    'tripDts': JSON.parse(res?.trip_information) ?? '-',
                                    'offered_products': offer_products.join(', ') ?? '-',
                                    'offerDate': res?.r_timestamp.slice(0,-8) ?? '-'
                                });
                            } else {
                                this.appResults.push({
                                    'offerName': res?.name ?? '-',
                                    'offered_products': offer_products.join(', ') ?? '-',
                                    'offerDate': res?.r_timestamp.slice(0,-8) ?? '-',
                                    'tripDts' : {
                                        'res_name' : res?.resName ?? '-',
                                        'des_name' : res?.destName ?? '-',
                                        'travellersNumber' : res?.travellersNumber ?? '-',
                                        'start_date' : res?.start_date ?? '-',
                                        'end_date' : res?.end_date ?? '-',
                                    }
                                });
                            }
                        });                        
                    })
                } catch(err) {
                    console.warn(err)
                }
                finally {
                    this.finishOffer = true;
                }
            }
        }
    }
</script>
<style>
#jumDts {
    padding: 2rem 2rem .5rem 2rem;
}
.spanInfo {
    display: inline-block;
    width: 100%;
    max-width:300px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.list-group:not(.tripInfoGr) {
    box-shadow: 1px 0px 5px 4px rgba(93,129,145,0.75);
    -webkit-box-shadow: 1px 0px 5px 4px rgba(93,129,145,0.75);
    -moz-box-shadow: 1px 0px 5px 4px rgba(93,129,145,0.75);
}
.list-group-item:not(.tripInfo) {
    display: flex;
    align-items: center;
    margin-right:5px;
}
.list-group-item .badge {
    margin-left:5px;
}
#tabsLatest .card-header {
    padding-bottom: 0 !important;
}
</style>